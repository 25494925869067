import './footer_A.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from '../form/form';
import Map from '../map/map'
function footer(props) {
    return(
        <div>
            <div className="footer-container" id="footer">

                    <div className='zero'>
                        <div className='center-container'>
                            <div className='contact-form-container'>
                                <Form />
                                <div className='map-container animate-on-scroll'>
                                     <Map />
                                   <div className='address-container'>
                                    <div className='address animate-on-scroll'>
                                        <div className='label'> Διεύθυνση: </div>
                                        <div className='text'> Τρίτωνος 111, Παλαιό Φάληρο </div>
                                    </div>
                                    <span className='tel animate-on-scroll'>
                                        <div className='label'> Τηλέφωνο:</div>
                                        <div className='text'> <a href="tel:+306983855312"> 6983855312 </a></div>
                                    </span>
                                    <span className='email animate-on-scroll'>
                                        <div className='label'> Email:</div>
                                        <div className='text'> <a href = "mailto: info@asimakopouloupsy.gr">info@asimakopouloupsy.gr </a></div>
                                    </span>
                                    <div className='social-container animate-on-scroll'>
                                        <div className='title'>Follow us</div>
                                        <ul >
                                            <li><a className='facebook' href="https://www.facebook.com/profile.php?id=100089457861729"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a></li>
                                            <li><a className='linkedin' href="https://www.linkedin.com/in/maria-michaela-asimakopoulou-362251161/"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a></li>
                                            <li><a className='instagram' href="https://www.instagram.com/maria.michaela.asimakopoulou?igshid=YmMyMTA2M2Y'"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a></li>
                                        </ul>
                                    </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    <div className="first">
                        </div>
                        <div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default footer;
