import React from 'react';
//import PopupDetails from './PopupDetails';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import './map.scss';


export default function Map () {
  return (
      <MapContainer center={[37.9241470884359, 23.701606012771133]}
                    zoom={15}
                    scrollWheelZoom={false}>
        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[37.9241470884359, 23.701606012771133]} icon={new Icon({ iconUrl: require('../../images/pin.png'), iconSize: [30, 30]})}>
          </Marker>
      </MapContainer>
  )
}
