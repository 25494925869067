export const endpoint = 'https://asimakopouloupsy.gr';
window.onresize = () => {
    var width = window.innerWidth;
    if (width < 1199) {
        document.getElementById('root').classList.add('isMobile')
        document.getElementById('root').classList.remove('headerIsScrolled')
    }
    else {
        document.getElementById('root').classList.remove('isMobile')
    }
}
window.onload = () => {
    var width = window.innerWidth;
    if (width < 1199) {
        document.getElementById('root').classList.add('isMobile')
    }
    else {
        document.getElementById('root').classList.remove('isMobile')
    }
}

window.onscroll = () => {
    const headerHeight = document.querySelector('.App .header-container').offsetHeight;
    var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    if (!document.getElementById('root').classList.contains('isMobile')) {
        if (scrollTop >= headerHeight) {
            document.getElementById('root').classList.add('headerIsScrolled')
        }
        else {
            document.getElementById('root').classList.remove('headerIsScrolled')
        }
    }
}

