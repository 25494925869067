import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, FreeMode, EffectCards, EffectCreative } from "swiper";
import img2 from '../../images/gallery/gal2.jpg';
import img3 from '../../images/gallery/gal3.jpg';
import img4 from '../../images/gallery/gal4.jpg';
import img5 from '../../images/gallery/gal5.jpg';
import img6 from '../../images/gallery/gal6.jpg';
import img7 from '../../images/gallery/gal7.jpg';
import './gallery.scss';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";

SwiperCore.use([Navigation, Thumbs, FreeMode]);

function Gallery(props) {
  const thumbsSwiperRef = useRef(null);
  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    setKey(Date.now());
  }, []);
  return (
    <div className="gallery-container">
      <div className="center-container">
        <div className="container">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        navigation={true}
        thumbs={{ swiper: thumbsSwiperRef.current }}
        className="mySwiper2"
      >
        <SwiperSlide>
        <img src={img2} alt=''/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img3} alt=''/>
        </SwiperSlide> 
        <SwiperSlide> 
        <img src={img4} alt=''/> 
        </SwiperSlide>
        <SwiperSlide>
        <img src={img5} alt=''/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img6} alt=''/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img7} alt=''/>
        </SwiperSlide>
            </Swiper>
                <Swiper
                    onSwiper={(swiper) => (thumbsSwiperRef.current = swiper)}
                    spaceBetween={10}
                    slidesPerView={2}
                    breakpoints={{
                        // when window width is <= 499px
                        1024: {
                          slidesPerView: 4
                        },
                        // when window width is <= 1023px
                        499: {
                          slidesPerView: 3
                        },
                      }}
                    freeMode={true}
                    
                    navigation={true}
                    watchSlidesProgress={true}
                    className="mySwiper"
                >
        <SwiperSlide>
        <img src={img2} alt=''/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img3} alt=''/>
        </SwiperSlide> 
        <SwiperSlide> 
        <img src={img4} alt=''/> 
        </SwiperSlide>
        <SwiperSlide>
        <img src={img5} alt=''/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img6} alt=''/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img7} alt=''/>
        </SwiperSlide>
                </Swiper>
            </div>
        </div>
      </div>
    )
}
export default Gallery;