
import './form.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react';

function form(props) {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [age, setAge] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [comment, setComment] = useState('');
    const handleSubmit = (e) => {
        const button = e.currentTarget;
        
        if(name === '' || surname === '' || age === '' || email === '' || phone === '' || mobile === '' || comment === '') {
            const popup = document.getElementById('popup');
            popup.classList.remove('hidden');
            setTimeout(() => {
                popup.classList.add('hidden');
            }, 3000);
            return;
        }
        const category =
        {
            "mail": email,
            "name": name,
            "surname": surname,
            "phone": phone,
            "mobile": mobile,
            "age": age,
            "comments": comment
        }

        return fetch('https://asimakopouloupsy.gr/mail', { method: 'POST', body: JSON.stringify(category), mode: 'cors', headers: { 'Content-Type': 'application/json', 'key': 'beed2d97-90a1-462f-87d5-b507d58cd4a3' } })
            .then(response => { response.json() 
                button.classList.add('eatTheBox') }
            )
    }

    return (
        <div className="form-container animate-on-scroll">
                <div id='popup' className='hidden'>
                    <div className='title'>Δεν έχετε συμπληρώσει κάποιο πεδίο!</div>
                </div>
            <span className='title'>Φόρμα επικοινωνίας </span>
            <form >
                <br />
                <div className='input'>
                    <input name='name' type='text' placeholder=' ' value={name} onChange={e => setName(e.target.value)} />
                    <label> 'Ονομα*</label>
                </div>
                <div className='input'>
                    <input name='surname' type='text' placeholder=' ' value={surname} onChange={e => setSurname(e.target.value)} />
                    <label> Επίθετο*</label>
                </div>

                <br />
                <br />
                <div className='input'>
                    <input name='age' type='text' placeholder=' ' value={age} onChange={e => setAge(e.target.value)} />
                    <label> Ηλικία*</label>
                </div>
                <div className='input'>
                    <input name='email' type='email' placeholder=' ' value={email} onChange={e => setEmail(e.target.value)} />
                    <label> Email*</label>
                </div>
                <br />
                <br />
                <div className='input'>
                    <input name='phone' type='text' placeholder=' ' value={phone} onChange={e => setPhone(e.target.value)} />
                    <label> Τηλέφωνο</label>
                </div>
                <div className='input'>
                    <input name='mobile' type='text' placeholder=' ' value={mobile} onChange={e => setMobile(e.target.value)} />
                    <label> Κινητό </label>
                </div>
                <br />
                <br />
                <div className='textarea'>
                    <textarea placeholder=' ' value={comment} onChange={e => setComment(e.target.value)} >

                    </textarea>
                    <label> Σχόλια </label>
                </div>

                <br />
                <br />
                <br />
                <div className='button' onClick={handleSubmit}><span className='mainText'>Υποβολή </span><FontAwesomeIcon icon="fa-solid fa-paper-plane" /> <span className='text'><FontAwesomeIcon icon="fa-solid fa-envelope-circle-check" /> Η αίτηση σας καταχωρήθηκε!</span></div>
            </form>
        </div>
    )
}
export default form;