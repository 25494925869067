import logo from '../../images/logo/logo.png'
import Menu from '../menu/menu'
import './header_A.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

function header(props) {
    const openCloseMenu = () => {
        var menu = document.getElementsByClassName('mobile-menu-container');
        if(menu[0].classList.contains('mobile-hidden')){
            document.getElementsByTagName('body')[0].classList.add('no-scroll');
            menu[0].classList.remove('mobile-hidden');
        }
        else { 
            document.getElementsByTagName('body')[0].classList.remove('no-scroll');
            menu[0].classList.add('mobile-hidden');
        }
        
    }
    return( 
        <div>
            <div className='header-space' />
            <div className="header-container">
                <div className='center-container'>
                    <div className='zero'>
                        <ul class='contact-container'>
                        </ul>
                    </div>
                    <div className="first">
                        <div className='mobile-menu'>
                            <a href='javascript:void(0)' onClick={openCloseMenu}><FontAwesomeIcon icon="fa-solid fa-bars" /></a>
                            <div className="mobile-menu-container mobile-hidden">
                                <Menu isMobile = {true} openCloseMenu={openCloseMenu}/>
                            </div>
                        </div>
                        <div className="card-container">
                            <Link class='logo-container' to='/Home'>
                                <img className="logo" src={logo} alt="logo"></img>
                            </Link>
                            <div className="card-literals">
                                <span className='name'>Μαρία-Μιχαέλα Ασημακοπούλου</span>
                                <span className='profession1'>Ψυχολόγος - Ψυχοθεραπεύτρια</span>
                            </div>
                        </div>
                        <div className='header-menu-container'>
                            <Menu />
                        </div>
                        <div className="social-container">
                            <ul >
                                <li><a className='facebook' href="https://www.facebook.com/profile.php?id=100089457861729"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a></li>
                                <li><a className='linkedin' href="https://www.linkedin.com/in/maria-michaela-asimakopoulou-362251161/"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a></li>
                                <li><a className='instagram' href="https://www.instagram.com/maria.michaela.asimakopoulou?igshid=YmMyMTA2M2Y'"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='header-sticky-container'>
                <div className='center-container'>
                    <div className='fluid'>
                    <div className="card-container">
                            <Link class='logo-container' to='./Home'>
                                <img className="logo" src={logo} alt="logo"></img>
                            </Link>
                            <div className="card-literals">
                                <span className='name'>Μαρία-Μιχαέλα Ασημακοπούλου</span>
                                <span className='profession1'>Ψυχολόγος - Ψυχοθεραπεύτρια</span>
                            </div>
                        </div>
                        <div className='header-menu-container'>
                            <Menu />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default header;