import logo from './logo.svg';
import './App.css';
import Header from '../src/components/header/header_A'
import Footer from '../src/components/footer/footer_A'
import Home from '../src/pages/home'
import CV from './pages/CV';
import Therapeutical from './pages/therapeutical';
import Services from './pages/services';
import '../src/global/styling/generic.scss';
import '../src/global/js/generic.js';
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,Navigate
} from "react-router-dom";
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faStreetView, faChevronDown, faBars, faAddressCard, faUpLong, faCalendarCheck, faPaperPlane, faEnvelopeCircleCheck} from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';

library.add(faFacebook, faTwitter, faInstagram, faLinkedin, faPhone, faStreetView,faChevronDown, faBars, faAddressCard, faUpLong, faCalendarCheck, faPaperPlane, faEnvelopeCircleCheck)
function App() {
  const location = useLocation();
  useEffect(() => {
    const initializeAPIObserver = () => {
      const animation_elements = document.querySelectorAll('.animate-on-scroll, .animate-top-down, .animate-down-top, .animate-on-scroll-right');
  
      const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  entry.target.classList.add('animate');
              } else {
                  //entry.target.classList.remove('animate');
              }
          })
      }, {
          threshold: [0.1]
      });
  
      for (let i = 0; i < animation_elements.length; i++) {
          const el = animation_elements[i];
  
          observer.observe(el);
      }
  }
    
    initializeAPIObserver();
    window.scrollTo({ top: 0 });

  },[location]);
  
  return (
    
    <div className="App">
      <div className='scrollToTop' onClick={()=> {window.scrollTo({ top: 0, behavior: 'smooth' });}}>
      <FontAwesomeIcon icon="fa-solid fa-up-long" />
      </div>
      <Header />
    <div className='main-content'>
      
      <Routes>
      <Route path="/" element={<Navigate to="/Home" />} />
      <Route path="/About-me" element={<CV />} />
      <Route path="/About-me/:id" element={<CV />} />
      <Route path="/Therapeutiki-proseggisi" element={<Therapeutical />} />
      <Route path="/Therapeutiki-proseggisi/:id" element={<Therapeutical />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Services/id=:id" element={<Services />} />
      <Route path="/Home" element={<Home />} />
      </Routes>
  </div>
  <Footer />
  
  </div>
      
  );
}

export default App;
