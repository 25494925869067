import './services.scss'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import consult from '../images/services/consult.jpg'
import couple from '../images/services/couple.jpg'
import person from '../images/services/person.jpg'
import system from '../images/services/system.jpg'
import ServicesList from '../components/servicesList/servicesList';
function services(props) {
    const text = {
        "0": {
            "image": "atomiki",
            "title": 'ΑΤΟΜΙΚΗ ΘΕΡΑΠΕΙΑ',
            "description": `<p> Η θεραπευτική διαδικασία αποτελεί ένα ταξίδι αναζήτησης και βαθύτερης κατανόησης του εαυτού. Η θεραπευτική σχέση θα αποτελέσει το ασφαλές πλαίσιο μέσα στο οποίο ο θεραπευόμενος θα μπορέσει να εξερευνήσει βαθύτερα κομμάτια του εαυτού του, να μοιραστεί τυχόν τραυματικές εμπειρίες και να βιώσει επανορθωτικά την εμπειρία του σχετίζεσθαι. Με αφετηρία το αίτημά του, θα μπορέσει να ανακαλύψει και να νοηματοδοτήσει την μέχρι τώρα ιστορία του, να ενδυναμώσει τα πιο δυνατά κομμάτια του εαυτού του και σταδιακά να αναγνωρίσει και να ακούσει τη δική του εσωτερική φωνή. Βγαίνοντας από το ταξίδι της αναζήτησης, θα έχει μάθει να αντιμετωπίζει με περισσότερη ευελιξία και σιγουριά τις δυσκολίες της ζωής, αξιοποιώντας τις προσωπικές του ικανότητες και την βοήθεια του υποστηρικτικού του δικτύου.</p><br></br>
            <p>
            Η ατομική ψυχοθεραπεία γίνεται με συνεδρίες σε εβδομαδιαία βάση, σε προγραμματισμένη μέρα και ώρα, διάρκειας 50 λεπτών.
            </p>`
        },
        "1": {
            "image": "therapia",
            "title": "ΘΕΡΑΠΕΙΑ ΖΕΥΓΟΥΣ",
            "description": `<p>Η συντροφική σχέση αποτελεί την πιο στενή ενήλικη σχέση στη ζωή ενός ατόμου. Αυτό που την καθιστά τόσο σημαντική, είναι η εγγύτητα και η οικειότητα που αναπτύσσεται ανάμεσα στους συντρόφους, η οποία είναι ανάλογη με αυτή που υπάρχει ανάμεσα σ’ ένα βρέφος με το πρόσωπο που το φροντίζει (συνήθως τη μητέρα). Οι σύντροφοι αναζητούν την ανάπτυξη ενός συναισθηματικού δεσμού που θα τους προσφέρει ένα καταφύγιο ανακούφισης και ασφάλειας, όπως συμβαίνει με το βρέφος και την μητέρα. </p><br></br>
            <p>Κατά τη διάρκεια της σχέσης του ζευγαριού κι ιδιαίτερα σε περιόδους αλλαγών για το ζευγάρι, όπως η συγκατοίκηση ή η απόκτηση παιδιών, ή σε στιγμές που προκύπτει κάτι δύσκολο για κάποιον ή και τους δύο συντρόφους, το ζευγάρι μπορεί να αντιμετωπίσει δυσκολίες στη σχέση και να αναδυθούν άλυτα συναισθηματικά ζητήματα του παρελθόντος που διαταράσσουν το αίσθημα αυτής της ασφάλειας. Τότε είναι που το ζευγάρι, συνήθως, αποφασίζει να απευθυνθεί σ’ έναν ειδικό ψυχικής υγείας. </p><br></br>
            <p>Η δυναμική της συντροφικής σχέσης καλλιεργεί το έδαφος όπου παλαιότερα ζητήματα προς επίλυση έρχονται στην επιφάνεια και με την κατάλληλη διεργασία μπορούν να επουλωθούν. Στόχος της θεραπείας ζεύγους είναι το κάθε μέλος του ζευγαριού να εντοπίσει τα μοτίβα που σχετίζεται, να καταλάβει καλύτερα και να αποδεχτεί ουσιαστικά τον εαυτό του και τον σύντροφό του, ώστε να μπορέσει να αποκατασταθεί η ασφάλεια που προσφέρει η συντροφική σχέση.   </p><br></br>
            <p>Η θεραπεία ζεύγους γίνεται με συναντήσεις συνήθως ανά δύο εβδομάδες, διάρκειας 60 λεπτών, σε προγραμματισμένη μέρα και ώρα. Σε περίπτωση που κριθεί σκόπιμο, μπορεί παράλληλα με την θεραπεία ζεύγους να πραγματοποιηθούν συνεδρίες και ατομικά. 
            
            </p>`
        },
        "2": {
            "image": "simbouleutiki",
            "title": "ΣΥΜΒΟΥΛΕΥΤΙΚΗ",
            "description": `<p> Οι συνεδρίες συμβουλευτικής αφορούν ένα συγκεκριμένο αίτημα που φέρνει ένα άτομο, ζευγάρι ή οικογένεια. Κατά τη διάρκεια των συνεδριών συμβουλευτικής  γίνεται περαιτέρω διερεύνηση του αιτήματος του συμβουλευόμενου, με βάση τις αρχές της συστημικής προσέγγισης, εστιάζοντας στο “εδώ και τώρα”. </p><br></br>
            <p>Οι συνεδρίες συνήθως ορίζονται στις 10-12 κι έχουν διάρκεια 50 λεπτά. </p><br></br>`
        },
        "3": {
            "image": "online",
            "title": "ONLINE ΣΥΝΕΔΡΙΕΣ",
            "description": `<p> Η πρόσβαση στο γραφείο του θεραπευτή μπορεί να αποτελεί για κάποιον δύσκολη υπόθεση. Η διαμονή σε μια άλλη χώρα ή απομακρυσμένη περιοχή, κάποια κινητική δυσκολία, η αγοραφοβία, και πολλά ακόμα, μπορεί να αποτελεί κάποιον από τους λόγους που κάποιος αδυνατεί να κάνει δια ζώσης συνεδρίες. Οι διαδικτυακές συνεδρίες δίνουν την δυνατότητα στα άτομα να πάρουν την βοήθεια που χρειάζονται. Ο τρόπος που λαμβάνει χώρα η θεραπευτική διαδικασία (χρόνος, δεοντολογία, τεχνικές κλπ)  παραμένει ο ίδιος, καθιστώντας τις online συνεδρίες μια ασφαλή εναλλακτική. Σε περιπτώσεις που υπάρχει η δυνατότητα ή κριθεί απαραίτητο, μπορεί να γίνουν συναντήσεις κι από κοντά.  </p><br></br>`
        }
    }
    const { id } = useParams();
    return (
        <div>
            <div className="services-container">
                <section className='main-description box animate-on-scroll'>
                    {id && <div>
                        <div className='main-image-container'>
                            <img src={require(`../images/webp/${text[id].image}.webp`)}></img>
                        </div>
                        <h2>{text[id].title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: text[id].description }} ></p>
                    </div>
                    }
                </section>
                
                <section className='services-container'>
                <div className='center-container'>
                    <div className='container'>
                        <h2 className='title animate-on-scroll'> ΥΠΗΡΕΣΙΕΣ </h2>
                        <ul className='services-list'>
                            <li className='service animate-on-scroll'>
                                <Link to='/Services/id=0'>
                                    <div className='image-container'>
                                        <img src={require('../images/webp/atomiki.webp')} />
                                    </div>
                                    <div className='description'>
                                        <h3 className='title'> ΑΤΟΜΙΚΗ ΘΕΡΑΠΕΙΑ </h3>
                                    </div>
                                </Link>
                            </li>
                            <li className='service animate-on-scroll'>
                                <Link to='/Services/id=1'>
                                    <div className='image-container'>
                                        <img src={require('../images/webp/therapia.webp')} />
                                    </div>
                                    <div className='description'>
                                        <h3 className='title'> ΘΕΡΑΠΕΙΑ ΖΕΥΓΟΥΣ</h3>
                                    </div>
                                </Link>
                            </li>
                            <li className='service animate-on-scroll'>
                                <Link to='/Services/id=2'>
                                    <div className='image-container'>
                                        <img src={require('../images/webp/simbouleutiki.webp')} />
                                    </div>
                                    <div className='description'>
                                        <h3 className='title'> ΣΥΜΒΟΥΛΕΥΤΙΚΗ </h3>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>


            

            <section className='online-container'>
                <div className='center-container'>
                    <div className='container'>
                        <div className='image-container animate-on-scroll'>
                            <img src={require('../images/webp/online.webp')} />
                        </div>
                        <div className='desc-container animate-on-scroll'>
                            <div className='description'>
                                <h3>Συνεδρίες μέσω διαδικτύου</h3>
                            </div>
                            <div className='button-container animate-on-scroll'>
                                <Link to='/Services/id=3' className='btn'> Μάθε περισσότερα</Link>
                                <div className='btn' onClick={()=> { document.getElementById('footer').scrollIntoView({behavior: 'smooth'});}}> Κλείσε ραντεβού</div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            </div>
        </div>
    )
}
export default services;