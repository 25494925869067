import './home.scss'
import SliderCards from '../components/slider/slidercards';
import ServicesList from '../components/servicesList/servicesList';
import Qna from '../components/qna/qna';
import { Link } from 'react-router-dom';
function home(props) {
    return (
        <div className="home-container">
            <section className='main-description image-container animate-on-scroll'>
                <div className='image-container'>
                    <img src={require('../images/webp/home.webp')} />
                </div>
            </section>
            <section className='description-container'>
                <div className='center-container'>
                    <div className='container'>
                        <h1 className='title animate-on-scroll'>
                            Ψυχοθεραπεία με δύο λόγια
                        </h1>
                        <div className='description animate-on-scroll'>
                            Είναι η συμπόρευση σ'ένα ταξίδι αναζήτησης, ενδυνάμωσης και αποδοχής του εαυτού μας.
                            <br /> <br />
                            Η θεραπευτική σχέση δημιουργεί το ασφαλες πλαίσιο που επιτρέπει <br />
                            την έκφραση των συναισθημάτων, των ανησυχιών και των προβληματισμών μας <br /> με στόχο το βίωμα επανορθωτικών εμπειριών.
                        </div>
                        <div className='signature animate-on-scroll'>
                            <img src={require('../images/webp/signature.webp')} />
                        </div>
                    </div>
                </div>
            </section>

            <section className='services-container'>
                <div className='center-container'>
                    <div className='container'>
                        <h2 className='title animate-on-scroll'> ΥΠΗΡΕΣΙΕΣ </h2>
                        <ul className='services-list'>
                            <li className='service animate-on-scroll'>
                                <Link to='/Services/id=0'>
                                    <div className='image-container'>
                                        <img src={require('../images/webp/atomiki.webp')} />
                                    </div>
                                    <div className='description'>
                                        <h3 className='title'> ΑΤΟΜΙΚΗ ΘΕΡΑΠΕΙΑ </h3>
                                    </div>
                                </Link>
                            </li>
                            <li className='service animate-on-scroll'>
                                <Link to='/Services/id=1'>
                                    <div className='image-container'>
                                        <img src={require('../images/webp/therapia.webp')} />
                                    </div>
                                    <div className='description'>
                                        <h3 className='title'> ΘΕΡΑΠΕΙΑ ΖΕΥΓΟΥΣ </h3>
                                    </div>
                                </Link>
                            </li>
                            <li className='service animate-on-scroll'>
                                <Link to='/Services/id=2'>
                                    <div className='image-container'>
                                        <img src={require('../images/webp/simbouleutiki.webp')} />
                                    </div>
                                    <div className='description'>
                                        <h3 className='title'> ΣΥΜΒΟΥΛΕΥΤΙΚΗ </h3>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>


            

            <section className='online-container'>
                <div className='center-container'>
                    <div className='container'>
                        <div className='image-container animate-on-scroll'>
                            <img src={require('../images/webp/online.webp')} />
                        </div>
                        <div className='desc-container animate-on-scroll'>
                            <div className='description'>
                                <h3>Συνεδρίες μέσω διαδικτύου</h3>
                            </div>
                            <div className='button-container animate-on-scroll'>
                                <Link to='/Services/id=3' className='btn'> Μάθε περισσότερα</Link>
                                <div className='btn' onClick={()=> { document.getElementById('footer').scrollIntoView({behavior: 'smooth'});}}> Κλείσε ραντεβού</div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className='systemic-container'>
                <div className='center-container'>
                    <div className='container'>
                        <div className='image-container animate-on-scroll'>
                            <img src={require('../images/webp/sistimiki.webp')} />
                        </div>
                        <div className='desc-container'>
                            <div className='title-container animate-on-scroll'>
                                <h3 className='title'>ΣΥΣΤΗΜΙΚΗ ΨΥΧΟΘΕΡΑΠΕΙΑ</h3>
                            </div>
                            <div className='description animate-on-scroll'>
                                Η ψυχοθεραπεία είναι μια διαδικασία μέσα από την οποία το άτομο, με τη βοήθεια ενός ειδικού, αναζητά λύσεις σε ζητήματα που το απασχολούν. Αυτά μπορεί να αφορούν δυσκολίες στις σχέσεις, σωματικά συμπτώματα που οφείλονται σε ψυχολογικά αίτια, δυσλειτουργικότητα στην καθημερινή ζωή (π.χ άγχος, θλίψη κ.ά), αλλά και να μην είναι σαφώς προσδιορισμένα(π.χ καλύτερη κατανόηση του εαυτού). 
                            </div>
                            <div className='button-container animate-on-scroll'>
                                <Link to='' className='btn green'> διαβάστε περισσότερα...</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='qna-container'>
                <div className='center-container'>
                    <div className='container'>
                        <Qna />
                    </div>
                </div>
            </section>
        </div >
    )
}
export default home;