import Gallery from '../components/gallery/gallery';
import { useParams } from 'react-router-dom';
import './CV.scss'
import img from '../images/mm1.jpg';
function CV(props) {
    const { id } = useParams();
    return (
        <div>
            <div className="CV-container">

                {id!=='id=1' && 
                <section className='main-description box animate-on-scroll' id='0'>
                    <div className='image-container'>
                        <img src={img} alt='Μαρία-Μιχαέλα Ασημακοπούλου' />
                    </div>
                   <p> Η Μαρία- Μιχαέλα Ασημακοπούλου γεννήθηκε και μεγάλωσε στην Αθήνα. Το ενδιαφέρον της για την ψυχολογία και τις ανθρώπινες σχέσεις την οδήγησαν να σπουδάσει Ψυχολογία. Έτσι, το 2016 αποφοίτησε από το Τμήμα Ψυχολογίας του Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών. </p><br></br>
<p>Με το πέρας των προπτυχιακών της σπουδών, ειδικεύτηκε στη Συστημική Ψυχοθεραπεία στο τετραετές κλινικό πρόγραμμα του Ινστιτούτου «Λόγω Ψυχής». Στη συγκεκριμένη εκπαίδευση η συστημική θεώρηση εμπλουτίζεται με τη θεωρία του συναισθηματικού δεσμού, την αφηγηματική ψυχολογία και τα συναφή σύγχρονα ευρήματα των νευροεπιστημών. Η ψυχοθεραπευτική αυτή προσέγγιση εφαρμόζεται σε συνεδρίες με άτομα, ζευγάρια, οικογένειες και ομάδες.</p><br></br>
<p>Παράλληλα με τις σπουδές της ξεκίνησε να παρακολουθεί και να συμμετέχει σε σεμινάρια και ημερίδες που αφορούν την ψυχική υγεία. Επιπλέον, έχει παρακολουθήσει κι ολοκληρώσει με επιτυχία το ετήσιο μετεκπαιδευτικό Σεμινάριο στην Κλινική Ψυχοπαθολογία «ΠΑΝΑΓΙΩΤΗΣ ΟΥΛΗΣ», το οποίο διοργανώνεται από την Ψυχιατρική Κλινική του Πανεπιστημίου Αθηνών του Αιγινητείου Νοσοκομείου. </p><br></br>
<p>Ως ψυχολόγος, έχει δουλέψει με παιδιά με μαθησιακές και κοινωνικές δυσκολίες, κι  υποστηρίζει έργα και δράσεις κοινωνικής προστασίας πληθυσμιακών ομάδων - στόχων σε τοπικό επίπεδο. Κατά τη διάρκεια της ειδίκευσής της στη συστημική ψυχοθεραπεία, ξεκίνησε να δουλεύει ψυχοθεραπευτικά με άτομα στο πλαίσιο της Συμβουλευτικής Υπηρεσίας χαμηλού κόστους υπό την εποπτεία του Ινστιτούτου “Λόγω Ψυχής”. Σήμερα, δουλεύει ιδιωτικά με άτομα, ζευγάρια, δια ζώσης και διαδικτυακά. 

                    </p>
                </section>
                }
                { id=='id=1' &&
                <section className='main-gallery animate-on-scroll' id='1'>
                    <div className='title'>O ΧΩΡΟΣ</div>
                <Gallery />

                </section>
                }
            </div>
        </div>
    )
}
export default CV;