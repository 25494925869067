
import './qna.scss'
import image from '../../images/im1.jpg'
import image1 from '../../images/im2.jpg'
import image2 from '../../images/im3.jpg'
import image3 from '../../images/im4.jpg'

function Qna(props) {
    const openCloseQna = (e)=>{
        const element = e.currentTarget;
        if(element.classList.contains('opened')){
            element.classList.remove('opened')
            element.classList.add('closed')
        }
        else {
            element.classList.remove('closed')
            element.classList.add('opened')
        }
    }
    return (
        <div>
            <div className="qna-container ">
                <span className='main-title'>ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ</span>
                <div className='qnaList-container'>
                    <div className='qna-box  animate-on-scroll closed' onClick={(e)=>openCloseQna(e)}>
                        <div className='box-container'>
                            <div className='title-container'>
                                <div className='title'>Πότε να ξεκινήσω ψυχοθεραπεία; </div>
                            </div>
                        <div className='text-container'>
                            <div className='text'>Δεν υπάρχει “κατάλληλη στιγμή”. Αρκεί να υπάρχει διάθεση για δέσμευση στη διαδικασία, εσωτερική αναζήτηση κι αποδοχή του εαυτού.</div>
                        </div> 
                        </div>
                    </div>
                    <div className='qna-box  animate-on-scroll closed' onClick={(e)=>openCloseQna(e)}>
                        <div className='box-container'>
                            <div className='title-container'>
                                <div className='title'>Γιατί να ξεκινήσω ψυχοθεραπεία; </div>
                            </div>
                        <div className='text-container'>
                            <div className='text'>
                                Η ψυχοθεραπεία συμβάλει στην βαθύτερη κατανόηση του εαυτού που με τη σειρά της φέρει αυτοαποδοχή και εσωτερικές μετακινήσεις. Έτσι, κάτι που αρχικά μπορεί να μοιάζει δύσκολό ή ανυπόφορο σταδιακά γίνεται πιο βατό.
                            </div>
                        </div> 
                        </div>
                    </div>
                    <div className='qna-box  animate-on-scroll closed' onClick={(e)=>openCloseQna(e)}>
                        <div className='box-container'>
                            <div className='title-container'>
                                <div className='title'>Ποιά προσέγγιση να επιλέξω;  </div>
                            </div>
                        <div className='text-container'>
                            <div className='text'>
                                Οι θεραπευτικές προσεγγίσεις που υπάρχουν είναι αρκετές. Κάποιες από τις γνωστότερες είναι η Ψυχοδυναμική (αναζητά τις ασυνείδητες τάσεις που επηρεάζουν την συμπεριφορά του ατόμου), η Συστημική (αντιμετωπίζει το άτομο ως μέρος των ευρύτερων συστημάτων που ανήκει και διερευνά τις σχέσεις που δημιουργεί) και η Γνωσιακή- Συμπεριφοριστική (εστιάζει στον τρόπο που το άτομο ερμηνεύει τα γεγονότα της ζωής του). Ωστόσο, στην θεραπευτική διαδικασία πρωτεύοντα ρόλο έχει η θεραπευτική  σχέση. Η ύπαρξη εμπιστοσύνης κι αισθήματος αποδοχής είναι το πιο σημαντικό συστατικό στην ψυχοθεραπευτική πορεία.
                            </div>
                        </div> 
                        </div>
                    </div>
                    <div className='qna-box  animate-on-scroll closed' onClick={(e)=>openCloseQna(e)}>
                        <div className='box-container'>
                            <div className='title-container'>
                                <div className='title'>Δεν χρειάζομαι ψυχοθεραπεία. Έχω φίλους!</div>
                            </div>
                        <div className='text-container'>
                            <div className='text'>
                            Η ύπαρξη σημαντικών κι υποστηρικτικών ανθρώπων στη ζωή μας είναι ζωτικής σημασίας. Ωστόσο, η φύση, τα κίνητρα και το πλαίσιο της σχέσης με τους αγαπημένους μας ανθρώπους δεν έχουν την επιστημονική βάση που διέπει την θεραπευτική σχέση, η οποία συμβάλλει στην δημιουργία του κατάλληλου πλαισίου για μοίρασμα τραυματικών εμπειριών, αναζήτηση του εσωτερικού εαυτού και απόκτηση επανορθωτικών βιωμάτων.   
                            </div>
                        </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Qna;