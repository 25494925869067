import './therapeutical.scss'
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
function Therapeutical(props) {
    return (
        <div>
            <div className="Therapeutical-container">
                <section className='main-description box animate-on-scroll' id='0'>
                    <div className='main-image-container'>
                        <img src={require(`../images/therapeutical/img.jpg`)}></img>
                    </div>
                    <h2>ΣΥΣΤΗΜΙΚΗ ΨΥΧΟΘΕΡΑΠΕΙΑ</h2>
                   <p> Η ψυχοθεραπεία είναι μια διαδικασία μέσα από την οποία το άτομο, με τη βοήθεια ενός ειδικού, αναζητά λύσεις σε ζητήματα που τo απασχολούν. Αυτά μπορεί να αφορούν δυσκολίες στις σχέσεις, σωματικά συμπτώματα που οφείλονται σε ψυχολογικά αίτια, δυσλειτουργικότητα στην καθημερινή ζωή (π.χ. άγχος, θλίψη κ.α.), αλλά και να μην είναι σαφώς προσδιορισμένα (π.χ. καλύτερη κατανόηση του εαυτού).  </p><br></br>
<p>Στη συστημική ψυχοθεραπεία, το αίτημα του θεραπευόμενου εξετάζεται σε συνάρτηση με τα μοτίβα που ο θεραπευόμενος σχετίζεται με την οικογένειά του κι επαναλαμβάνει και στις υπόλοιπες σχέσεις του, τους ρόλους που υιοθετεί, τους τρόπους που διαχειρίζεται τα συναισθήματά του, τις προσδοκίες που έχει από τις σχέσεις που αναπτύσσει, τον τρόπο που αφηγείται για τον εαυτό του και τις “φωνές” των σημαντικών ανθρώπων που έχει υιοθετήσει.</p><br></br>
<p>Κεντρικό ρόλο στη θεραπευτική διαδικασία διαδραματίζει η σχέση που χτίζεται ανάμεσα στον ειδικευμένο ψυχοθεραπευτή και τον θεραπευόμενο. Η θεραπευτική αυτή σχέση δημιουργεί ένα ασφαλές πλαίσιο μέσα στο οποίο ο θεραπευόμενος μπορεί να επεξεργαστεί όσα του συμβαίνουν, να μοιραστεί τυχόν τραυματικές εμπειρίες του, τα συναισθήματά και τις σκέψεις του, καθώς και να διερευνήσει εναλλακτικούς τρόπους αντιμετώπισης και σκέψης που μπορεί να υιοθετήσει, με στόχο να μπορέσει να αποκτήσει βαθύτερη κατανόηση κι αποδοχή του εαυτού του.</p><br></br>
<p>Η συστημική ψυχοθεραπεία εφαρμόζεται σε άτομα, ζευγάρια, οικογένειες και ομάδες. 

                    </p>
                </section>
            </div>
        </div>
    )
}
export default Therapeutical;